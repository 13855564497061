
export default {

  state: {
    isShow: false,
    distributionDocument: null,
    distributionFile: null,
  },

  getters: {
    isShow: (state) => {
      return state.isShow
    },

    getDistributionDocument: (state) => {
      return state.distributionDocument
    },

    getDistributionFile: (state) => {
      return state.distributionFile
    },
  },

  mutations: {
    saveShowHide(state, payload) {
      state.isShow = payload
    },

    saveDistributionDocument(state, payload) {
      state.distributionDocument = payload
    },

    saveDistributionFile(state, payload) {
      state.distributionFile = payload
    },
  },

  actions: {
    showHideDialog({ commit }, context) {
      const isShow = context.isShow
      const distributionDocument = context?.distributionDocument || null
      const distributionFile = context?.distributionFile || null
      commit('saveShowHide', isShow)
      commit('saveDistributionDocument', distributionDocument)
      commit('saveDistributionFile', distributionFile)
    },
  },
}
