/**
 * メッセージ一覧
 * https://docs.google.com/spreadsheets/d/1pmbPfQC7P3lYzIwPl9Ji3COQXSbHDDeiOIExmbDNraU/edit#gid=77663669
 */

export default {
  // 共通メッセージ
  SYSTEM_MSG_0000: '該当するデータはありません。',
  SYSTEM_MSG_0001: 'ページが存在しません。',

  // 共通エラー
  SYSTEM_ERR_0000: 'システムエラーが発生しました。',
  SYSTEM_ERR_0001: 'ネットワークが切断されました。',
  SYSTEM_ERR_0002: 'DBの接続に失敗しました。',
  SYSTEM_ERR_0003: 'APIタイムアウトしました。',
  SYSTEM_ERR_0004: 'セッションの有効期限が切れました。',
  SYSTEM_ERR_0005: 'アクセス権がありません。',
  SYSTEM_ERR_0006: 'ご利用中のサービスのバージョンが最新ではないため、\n以下のボタンを押下して更新をお願いいたします。',
  SYSTEM_ERR_0007: 'コピーに失敗しました。',

  // バリデーションエラー
  INPUT_ERR_1000: '入力してください。',
  INPUT_ERR_1001: '50文字以下で入力してください。',
  INPUT_ERR_1002: '20文字以下で入力してください。',
  INPUT_ERR_1003: '100文字以下で入力してください。',
  INPUT_ERR_1004: '256文字以下で入力してください。',
  INPUT_ERR_1005: '24文字以下で入力してください。',
  INPUT_ERR_1006: '64文字以下で入力してください。',
  INPUT_ERR_1007: '1以上で入力してください。',
  INPUT_ERR_1008: '0以上で入力してください。',
  INPUT_ERR_1009: '3桁以下で入力してください。',

  INPUT_ERR_1011: '物件管理者を選択してください。',
  INPUT_ERR_1012: '48文字以下で入力してください。',
  INPUT_ERR_1013: '15文字以下で入力してください。',
  INPUT_ERR_1014: '2000文字以下で入力してください。',
  INPUT_ERR_1015: '9桁以下で入力してください。',
  INPUT_ERR_1016: '入力値の上限(9223372036854775807)を超えています。',
  INPUT_ERR_1017: '15桁以下で入力してください。',
  INPUT_ERR_1018: '10文字以下で入力してください。',
  INPUT_ERR_1019: '80文字以下で入力してください。',
  INPUT_ERR_1020: '128文字以下で入力してください。',
  INPUT_ERR_1021: '0以上365以下で入力してください。',
  INPUT_ERR_1022: '25文字以下で入力してください。',
  INPUT_ERR_1023: '13文字以下で入力してください。',
  INPUT_ERR_1024: '40文字以下で入力してください。',
  INPUT_ERR_1025: '6文字以下で入力してください。',
  INPUT_ERR_1026: '30文字以下で入力してください。',
  INPUT_ERR_1027: '35文字以下で入力してください。',

  INPUT_ERR_5000: '半角の英数字または-+@.!#_の記号で入力してください。',
  INPUT_ERR_5001: '半角の英大文字、英小文字、数字を含めた8文字以上36文字以下で入力してください。',
  INPUT_ERR_5002: '「@(アットマーク)」と「.(ドット)」を含めた半角英数字、記号で入力してください。',
  INPUT_ERR_5003: '整数で入力してください。',
  INPUT_ERR_5004: '連続した4文字以上の半角数字を含めて入力してください。',
  INPUT_ERR_5005: '正しい日付を入力してください。',
  INPUT_ERR_5006: '4桁の半角数字で入力してください。',
  INPUT_ERR_5007: '070/080/090で始まる11桁の半角数字で入力してください。',
  INPUT_ERR_5008: '6桁の半角数字で入力してください。',
  INPUT_ERR_5009: '半角数字と「-(ハイフン)」で入力してください。',
  INPUT_ERR_5010: '機種依存文字は使用できません。',
  INPUT_ERR_5011: '同じ書類名は使用できません。',
  INPUT_ERR_5012: '「@(アットマーク)」より前で「.(ドット)」の連続使用や、先頭や末尾への入力はできません。',
  INPUT_ERR_5013: '「@(アットマーク)」より前は64文字以下で入力してください。',
  INPUT_ERR_5014: '8桁の半角数字で入力してください。',
  INPUT_ERR_5015: '使用できない文字（¥ \\ / : * ? " < > |）が含まれています。',
  INPUT_ERR_5016: '半角数字または-+()の記号で入力してください。',
  INPUT_ERR_5017: '末尾は半角数字4桁で入力してください。',
  INPUT_ERR_5018: '数値（半角数値または「.(ドット)」「-(ハイフン)」）で入力してください。',
  INPUT_ERR_5019: '「.(ドット)」の連続使用はできません。',
  INPUT_ERR_5020: '「@(アットマーク)」の複数使用はできません。',
  INPUT_ERR_5021: '「.(ドット)」は先頭と末尾と「@(アットマーク)」の直前直後には入力できません。',
  INPUT_ERR_5022: '「-(ハイフン)」は末尾と「@(アットマーク)」の直後には入力できません。',
  INPUT_ERR_5023: "「@(アットマーク)」より前で「!#$%&'*+-/=?^_`{|}~.」以外の記号は入力できません。",
  INPUT_ERR_5024: '「@(アットマーク)」より後ろで「-.」以外の記号は入力できません。',
  INPUT_ERR_5025: '「@(アットマーク)」より後ろの3〜4文字目に「-(ハイフン)」の連続使用はできません。',
  INPUT_ERR_5026: '「@(アットマーク)」より後ろは67文字以下で入力してください。',
  INPUT_ERR_5027: '「@(アットマーク)」より後ろの最後の「.(ドット)」の直後に半角数字は入力できません。',
  INPUT_ERR_5028: 'YYYY/MM/DDで入力してください。',
  INPUT_ERR_5029: "全角カナ、半角英数字、スペース（全角/半角）、半角記号「&'(),-.」で入力してください。",
  INPUT_ERR_5030: '「@(アットマーク)」より前は64文字以下で入力してください。',
  INPUT_ERR_5031: 'スペース（全角/半角）のみは入力できません。',

  INPUT_ERR_6001: 'CSV形式のファイルを選択してください。',

  INPUT_ERR_6008: 'PDF形式のファイルを選択してください。',

  INPUT_ERR_6010: '10MBを超えるPDFは登録できません。',
  INPUT_ERR_6012: 'DOCX形式のファイルを選択してください。',
  INPUT_ERR_6013: '10MBを超えるテンプレートファイルは登録できません。',
  INPUT_ERR_6014: '20MB(20,970,496Byte)を超えるPDFは登録できません。',
  INPUT_ERR_6015: '18MBを超えるPDFは登録できません。',
  INPUT_ERR_6016: '10MB以上のテンプレートファイルは登録できません。',
  INPUT_ERR_6017: 'ファイルのエンコードはUTF-8またはShift-JISを指定してください。',
  INPUT_ERR_6018: '2行目のキーが不正です。',
  INPUT_ERR_6019: '3行目以降のデータが入力されていません。',
  INPUT_ERR_6020: '一度に登録可能な件数は200件までです。',
  INPUT_ERR_6021: 'ファイルの読み取りに失敗しました。',
  INPUT_ERR_6022: 'PDF形式、DOCX形式、XLSX形式のファイルを選択してください。',
  INPUT_ERR_6023: '20MB(20,970,496Byte)を超えるファイルは登録できません。',

  INPUT_ERR_9000: 'パスワードが一致しません。',
  INPUT_ERR_9002: '現在のパスワードと同じパスワードは設定できません。',
  INPUT_ERR_9003: 'FIPS物件番号に紐づくFIPS物件情報が登録されていません。',
  INPUT_ERR_9004: 'WES物件コードに紐づくWES物件情報が登録されていません。',
  INPUT_ERR_9005: '引渡予定日を超えた日付は指定できません。',

  // API（正常系）
  API_INFO_1000: 'ユーザーを追加しました。',
  API_INFO_1001: 'ユーザーを更新しました。',
  API_INFO_1002: 'ユーザーを削除しました。',
  API_INFO_1003: '契約書類テンプレート情報が登録されていません。',
  API_INFO_1004: '利用物件を更新しました。',
  API_INFO_1005: '対象期間内での集計結果がありません。',
  API_INFO_1006: 'プリファレンスを更新しました。',
  API_INFO_1007: '宅建士情報が登録されていません。',
  API_INFO_1008: '宅建士を追加しました。',
  API_INFO_1009: '宅建士を更新しました。',
  API_INFO_1010: '宅建士を削除しました。',
  API_INFO_1011: '物件情報が登録されていません。',
  API_INFO_1012: '該当する物件情報はありません。',
  API_INFO_1013: '物件を追加しました。',
  API_INFO_1014: '物件を更新しました。',
  API_INFO_1015: '物件担当者が登録されていません。',
  API_INFO_1016: '物件担当者を追加しました。',
  API_INFO_1017: '物件担当者を削除しました。',

  API_INFO_1021: '物件宅建士が登録されていません。',
  API_INFO_1022: '物件宅建士を追加しました。',
  API_INFO_1023: '物件宅建士を削除しました。',
  API_INFO_1024: 'パスワードを更新しました。',
  API_INFO_1025: '2要素認証設定をリセットしました。',
  API_INFO_1026: 'ログアウトしました。',

  API_INFO_1028: '契約ステータスを更新しました。',
  API_INFO_1029: '書類セットが登録されていません。',
  API_INFO_1030: '該当の共有者を契約者一覧から解除しました。',
  API_INFO_1031: '個人情報詳細を更新しました。',
  API_INFO_1032: '該当の共有者の契約者削除を取り消しました。',
  API_INFO_1033: '認証メールを送信しました。',
  API_INFO_1034: '代理人設定を更新しました。',
  API_INFO_1035: '代理人情報を更新しました。',
  API_INFO_1036: '契約グループが登録されていません。',
  API_INFO_1037: '書類セットを削除しました。',
  API_INFO_1038: '書類セットを送信しました。',
  API_INFO_1039: '送信を取り消しました。',
  API_INFO_1040: '承認しました。',
  API_INFO_1041: '承認を差し戻しました。',
  API_INFO_1042: '承認依頼を取り消しました。',
  API_INFO_1043: '書類セットを一時保存しました。',
  API_INFO_1044: '承認依頼しました。',
  API_INFO_1045: '契約締結済の書類セットを登録しました。',
  API_INFO_1046: '選択可能なユーザーが登録されていません。',
  API_INFO_1047: '物件ユーザーを追加しました。',
  API_INFO_1048: '物件ユーザーを削除しました。',
  API_INFO_1049: '契約者情報を更新しました。',
  API_INFO_1050: '書類PDFを追加しました。',
  API_INFO_1051: '書類PDFを削除しました。',
  API_INFO_1052: 'メールアドレスを認証しました。',
  API_INFO_1053: 'Cognitoユーザーを追加しました。',
  API_INFO_1054: 'メールアドレス認証を更新しました。',
  API_INFO_1055: '契約情報を更新しました。',
  API_INFO_1056: '物件詳細情報が登録されていません。',
  API_INFO_1057: '個人ステータスを更新しました。',
  API_INFO_1058: '契約詳細情報を更新しました。',
  API_INFO_1059: '物品サービス情報を追加しました。',
  API_INFO_1060: '物品サービス情報を削除しました。',
  API_INFO_1061: '物品サービス情報を更新しました。',
  API_INFO_1062: '契約詳細情報が登録されていません。',
  API_INFO_1063: '物品サービス情報が登録されていません。',
  API_INFO_1064: '原契約情報が登録されていません。',
  API_INFO_1065: '物件詳細情報を更新しました。',
  API_INFO_1066: 'パスワード再発行メールを送信しました。',
  API_INFO_1067: 'パスワードが変更されました。',
  API_INFO_1068: '承認グループが登録されていません。',
  API_INFO_1069: '承認グループを追加しました。',
  API_INFO_1070: '承認グループを削除しました。',
  API_INFO_1071: '承認グループを更新しました。',
  API_INFO_1072: '契約者情報を登録しました。',
  API_INFO_1073: '契約詳細情報を登録しました。',
  API_INFO_1074: '契約者情報が登録されていません。',
  API_INFO_1075: 'この契約書類テンプレートには流し込み情報がありません。',
  API_INFO_1076: '契約書類テンプレート情報を更新しました。',
  API_INFO_1077: '契約書類テンプレート情報を複製しました。',
  API_INFO_1078: '契約書類テンプレート情報を削除しました。',
  API_INFO_1079: 'テンプレートファイルを更新しました。',
  API_INFO_1080: '契約書類テンプレートの並び順を更新しました。',
  API_INFO_1081: '監査ログがありません。',
  API_INFO_1082: '書面捺印済の書類セットを登録しました。',
  API_INFO_1083: '書類セットの署名依頼をしました。',
  API_INFO_1084: '書類セットを取り消しました。',
  API_INFO_1085: '操作ログがありません。',
  API_INFO_1086: '顧客（契約者、代理人）が登録されていません。',
  API_INFO_1087: '連結ファイル（PDF）を更新しました。',
  API_INFO_1088: '連結ファイル（PDF）を削除しました。',
  API_INFO_1089: '書類セットの署名依頼をリマインドしました。',
  API_INFO_1090: 'ユーザーの一括登録をしました。',
  API_INFO_1091: 'この契約書類テンプレートには印字情報がありません。',
  API_INFO_1092: '仮パスワードを再送信しました。',
  API_INFO_1093: '発行済みの保証証書はありません。',
  API_INFO_1094: '保証証書を交付しました。',
  API_INFO_1095: '保証証書を引渡報告しました。',
  API_INFO_1096: '保証証書を解除報告しました。',
  API_INFO_1097: '保証証書を取消報告しました。',
  API_INFO_1098: '対象月の集計結果がありません。',
  API_INFO_1099: 'FIPS物件情報が登録されていません。',
  API_INFO_1100: 'FIPS会社情報が変更されました。',
  API_INFO_1101: '保証証書の発行依頼情報を一時保存しました。',
  API_INFO_1102: '保証証書を発行しました。',
  API_INFO_1103: 'FIPS物件情報を登録しました。',
  API_INFO_1104: 'FIPS物件情報を更新しました。',
  API_INFO_1105: 'FIPS物件情報を削除しました。',
  API_INFO_1106: '手付金等保証証書が登録されていません。',
  API_INFO_1107: '契約者の並び順を更新しました。',
  API_INFO_1108: '契約書類セットが登録されていません。',
  API_INFO_1109: '契約書類セットを削除しました。',
  API_INFO_1110: '契約書類セットを一時保存しました。',
  API_INFO_1111: '書面捺印済の契約書類セットを登録しました。',
  API_INFO_1112: '契約書類セットの署名依頼をしました。',
  API_INFO_1113: '契約書類セットを取り消しました。',
  API_INFO_1114: '契約書類セットの署名依頼をリマインドしました。',
  API_INFO_1115: '配布書類セットが登録されていません。',
  API_INFO_1116: '物件番号が登録されていません。',
  API_INFO_1117: '顧客が登録されていません。',
  API_INFO_1118: '配布書類セットを一時保存しました。',
  API_INFO_1119: '配布書類セットを取り消しました。',
  API_INFO_1120: '配布書類セットを削除しました。',
  API_INFO_1121: '配布書類セットの送信を取り消しました。',
  API_INFO_1122: '配布書類セットを送信しました。',
  API_INFO_1123: '仮審査申込一覧は登録されていません。',
  API_INFO_1124: '住宅ローン仮審査申込情報と顧客情報の連携を更新しました。',
  API_INFO_1125: 'SET物件情報がありません。',
  API_INFO_1126: '住宅ローン仮審査（SET）申込物件情報を追加しました。',
  API_INFO_1127: '住宅ローン仮審査（SET）申込物件情報を解除しました。',
  API_INFO_1128: '住宅ローン仮審査（SET）申込物件情報が登録されていません。',
  API_INFO_1129: '関係者情報が登録されていません。',
  API_INFO_1130: '関係者を追加しました。',
  API_INFO_1131: '関係者を更新しました。',
  API_INFO_1132: '関係者を削除しました。',
  API_INFO_1133: '物件関係者情報が登録されていません。',
  API_INFO_1134: '物件関係者情報を登録しました。',
  API_INFO_1135: '物件関係者情報を削除しました。',
  API_INFO_1136: '契約拡張情報を更新しました。',
  API_INFO_1137: '印字タグ情報が登録されていません。',
  API_INFO_1138: '印字タグ情報を更新しました。',
  API_INFO_1139: '契約拡張情報の並び順を更新しました。',
  API_INFO_1140: '契約拡張情報はありません。',
  API_INFO_1141: 'WES物件情報が登録されていません。',
  API_INFO_1142: 'WES会社情報が変更されました。',
  API_INFO_1143: 'WES物件情報を登録しました。',
  API_INFO_1144: 'WES物件情報を更新しました。',
  API_INFO_1145: 'WES物件情報を削除しました。',
  API_INFO_1146: 'WES保証証書を一括発行しました。',
  API_INFO_1147: '保証証書を回収報告しました。',
  API_INFO_1148: '保証証書を書損報告しました。',
  API_INFO_1149: 'WES物件コードが登録されていません。',
  API_INFO_1150: '配布書類セットの送信先が選択されていません。',
  API_INFO_1151: 'ファイルを返信しました。',

  // API（準正常系）
  API_WARN_1000: 'ログインユーザーのシステム管理者設定は変更できません。',
  API_WARN_1001: 'ログインユーザーは削除できません。',
  API_WARN_1002: '入力した物件IDは既に登録されています。',
  API_WARN_1003: '選択したユーザーは既に削除されています。',
  API_WARN_1004: '選択した宅建士は既に削除されています。',
  API_WARN_1005: '認証に失敗しました。営業担当者までご連絡をお願いいたします。',
  API_WARN_1006: '入力内容に誤りがあります。',
  API_WARN_1007: '有効期限が切れています。',
  API_WARN_1008: '認証回数の上限に達しました。営業担当者までご連絡をお願いいたします。',
  API_WARN_1009: '認証コードに誤りがあります。',
  API_WARN_1010: '認証回数の上限に達しました。再度メール内のリンクより、認証情報の登録からやり直してください。',
  API_WARN_1012: '入力した宅建士番号は既に登録されています。',
  API_WARN_1013: '選択した宅建士は既に登録されています。',
  API_WARN_1014: 'システム管理者が存在しなくなる為、システム管理設定は変更できません。',
  API_WARN_1015: 'システム管理者が存在しなくなる為、選択したユーザーを削除できません。',
  API_WARN_1016: '選択したユーザーは物件管理者に設定されている為、削除できません。',
  API_WARN_1017: '選択したユーザーは既に登録されています。',
  API_WARN_1018: '入力したユーザーIDは既に登録されています。',
  API_WARN_1019: '選択した物件は既に削除されています。',
  API_WARN_1020: '選択した契約者は既に削除されています。',
  API_WARN_1021: '選択したプリファレンスは既に削除されています。',
  API_WARN_1022: '選択したファイルは既に削除されています。',
  API_WARN_1023: '選択した契約グループは既に削除されています。',
  API_WARN_1024: 'メールアドレスが変更されているもしくは認証が取れていません。',
  API_WARN_1025: '選択した書類セットは既に削除されています。',
  API_WARN_1026: '選択した契約書類は既に登録されています。',
  API_WARN_1027: '入力したメールアドレスが契約グループ内で既に登録されています。',
  API_WARN_1029: 'ファイルのエンコードが非対応です。UTF-8またはShift-JISのファイルを選択してください。',
  API_WARN_1030: '機種依存文字は使用できません。',
  API_WARN_1031: '2行目に不正なキーが含まれています。',
  API_WARN_1032: '3行目以降のデータが入力されていません。',
  API_WARN_1033: '3行目以降のデータ内容に誤りがあります。',
  API_WARN_1034: '同一区画内でメールアドレスが重複しています。',
  API_WARN_1035: '入力したメールアドレスは既に登録されています。',
  API_WARN_1036: 'アカウントロック中です。しばらくしてから再度ログインしてください。',
  API_WARN_1037: '選択した契約書類は既に削除されています。',
  API_WARN_1038: '選択した送信者は既に解約されています。',
  API_WARN_1039: '対象の書類セットが存在しないか、ステータスが変更された可能性があります。',
  API_WARN_1040: '2行目のキー行が存在しません。',
  API_WARN_1041: 'CSVファイルが存在しません。',
  API_WARN_1042: 'CSVファイルではありません。',
  API_WARN_1043: 'ログイン情報の取得に失敗しました。再度ログインしてください。',
  API_WARN_1044: '契約者情報の取得に失敗しました。',
  API_WARN_1045: '選択したユーザーはシステム管理者または物件管理者の為、登録できません。',

  API_WARN_1047: '認証情報の登録に失敗しました。',
  API_WARN_1048: 'メールアドレスの更新に失敗しました。',
  API_WARN_1049: 'パスワードの更新に失敗しました。',
  API_WARN_1050: '送信先のメールアドレスが重複しています。',
  API_WARN_1051: 'メールアドレスが変更されているもしくは認証が取れていない契約者（代理人）が存在します。',
  API_WARN_1052: '選択した宅建士に誤りがあります。再度選択してください。',
  API_WARN_1053: '契約情報が存在しない為、更新できません。',
  API_WARN_1054: '物品サービス情報が存在しない為、更新できません。',
  API_WARN_1055: '選択した物品サービス情報は既に削除されています。',
  API_WARN_1056: '物件情報が存在しない為、更新できません。',
  API_WARN_1057: '選択した物件詳細情報は既に削除されています。',
  API_WARN_1058: '選択した物件詳細情報は物件に紐づいていない為、削除できません。',
  API_WARN_1059: 'テンプレート情報が存在しない為、更新できません。',
  API_WARN_1060: 'アカウントは既に登録済みの為、入力したパスワードは反映されませんでした。パスワードの変更が必要な場合は、ユーザ詳細で変更してください。',
  API_WARN_1061: 'メールアドレスが変更された為、入力したパスワードでアカウントが作成されました。',
  API_WARN_1062: '選択した物件承認グループは既に削除されています。',
  API_WARN_1063: '個人情報に未入力があります。',
  API_WARN_1064: '契約情報に未入力があります。',
  API_WARN_1065: '宅建士情報に未入力があります。',
  API_WARN_1066: '物件情報に未入力があります。',
  API_WARN_1067: '物品サービスに未入力があります。',

  API_WARN_1070: '原契約（個人情報）に未入力があります。',
  API_WARN_1071: '原契約（宅建士情報）に未入力があります。',
  API_WARN_1072: '原契約（契約情報）に未入力があります。',
  API_WARN_1073: '入力されていない承認レベルがある為、登録できません。',
  API_WARN_1074: '選択したユーザはシステム管理者、該当物件の物件管理者または物件担当者ではない為、登録できません。',
  API_WARN_1075: '承認グループメンバーが選択されていない為、登録できません。',
  API_WARN_1076: '承認依頼中の契約書類が存在する為、登録できません。',
  API_WARN_1077: '承認依頼中の契約書類が存在する為、削除できません。',
  API_WARN_1078: '選択した物件担当者は承認グループに属している為、削除できません。',
  API_WARN_1079: 'ログインIDに誤りがあります。ログインIDを忘れてしまった場合は、システム管理者までご連絡をお願いいたします。',
  API_WARN_1080: '選択したユーザーは担当者の為、更新できません。',
  API_WARN_1081: '該当承認レベルのメンバーではない為、承認差戻または承認することができません。',
  API_WARN_1082: '承認グループが選択されていません。',
  API_WARN_1083: '承認グループに属している為、システム管理設定は変更できません。',
  API_WARN_1084: '選択したユーザーには関連物件が存在する為、削除できません。',
  API_WARN_1085: '物品サービス情報の登録件数上限(1000件)を超える為、登録できません。',
  API_WARN_1086: '当物件では利用できない契約書類が含まれている為、登録できません。',
  API_WARN_1087: 'PDFファイルの変換に失敗しました。',
  API_WARN_1088: 'PDFファイル情報の取得に失敗しました。',
  API_WARN_1089: '選択した契約書類テンプレート情報は既に削除されています。',
  API_WARN_1090: '選択したファイルは未設定のタグ情報が存在するため、アップロードできません。',
  API_WARN_1091: '契約フェーズが存在しない為、更新できません。',
  API_WARN_1092: '契約書類テンプレート情報が存在しなくなる為、削除できません。',
  API_WARN_1093: '必須項目が未入力のため、契約書類作成に失敗しました。',
  API_WARN_1094: '契約書類テンプレートの並び替えに失敗しました。',
  API_WARN_1095: '総ファイルサイズの上限(9MB)を超える為、承認依頼できません。',
  API_WARN_1096: '選択した契約フェーズでは利用できない契約書類が含まれている為、登録できません。',
  API_WARN_1097: '選択した契約者（代理人）は既に削除されています。',
  API_WARN_1098: '選択した代理人は無効にされています。',
  API_WARN_1099: '削除された契約書類が含まれている為、登録できません。',
  API_WARN_1100: '総ファイルサイズの上限(50MB)を超える為、承認依頼できません。',
  API_WARN_1101: '送信先メールアドレスが重複しています。',
  API_WARN_1102: 'サイズ上限(20MB=20,970,496Byte)を超えるファイルが含まれている為、承認依頼できません。',
  API_WARN_1103: '契約書類の選択可能な上限数（20個）を超えているため、登録できません。',
  API_WARN_1104: '契約書類の選択可能な上限数（100個）を超えているため、登録できません。',
  API_WARN_1105: 'サイズ上限(18MB)を超える為、アップロードできません。',
  API_WARN_1106: 'メールアドレスが登録されていません。',
  API_WARN_1107: 'zipファイルの作成に失敗しました。',
  API_WARN_1108: 'zipファイル情報の取得に失敗しました。',
  API_WARN_1109: '同一契約グループ内でメールアドレスが重複しています。',
  API_WARN_1110: '入力したメールアドレスは既に登録されています。',
  API_WARN_1111: 'ユーザーの登録に失敗しました。',
  API_WARN_1112: '本パスワード設定済みのため、仮パスワードの再送信はできません。',
  API_WARN_1113: 'メールアドレスを変更する場合は、パスワードを入力してください。',
  API_WARN_1114: 'ユーザー一括登録では、パスワードは変更できません。',
  API_WARN_1115: '現在のパスワードと同じパスワードは使用できません。',
  API_WARN_1116: '選択可能なユーザー数（200）を超えているため、ダウンロードできません。',
  API_WARN_1117: '仮パスワードの有効期限が切れています。システム管理者までご連絡をお願いいたします。',
  API_WARN_1118: '選択したユーザーは削除されたか、メールアドレスが変更された可能性があります。',
  API_WARN_1119: '選択可能な契約グループ数（200）を超えているため、ダウンロードできません。',
  API_WARN_1120: '保証証書が存在しないか、ステータスが変更された可能性があります。',
  API_WARN_1121: '選択した物件番号が存在しません。',
  API_WARN_1122: '入力した物件番号は既に登録されています。',
  API_WARN_1123: '使用している発行依頼情報が存在する為、削除できません。',
  API_WARN_1124: '選択したFIPS会社情報が存在しません。',
  API_WARN_1125: '契約情報の取得に失敗しました。',
  API_WARN_1126: '契約者の並び替えに失敗しました。',
  API_WARN_1127: '選択した配布書類は既に削除されています。',
  API_WARN_1128: '選択した契約書類セットは既に削除されています。',
  API_WARN_1129: '対象の契約書類セットが存在しないか、ステータスが変更された可能性があります。',
  API_WARN_1130: '選択した契約者は既に登録されています。',
  API_WARN_1131: '対象の配布書類セットが存在しないか、送信取消に変更された可能性があります。',
  API_WARN_1132: '選択した契約者が送信先に存在しません。',
  API_WARN_1133: '選択した配布書類セットが存在しません。',
  API_WARN_1134: '選択した契約者は既に送信済みです。',
  API_WARN_1135: '選択した契約者に配布書類セットが送信済の契約者が含まれている為、更新できません。',
  API_WARN_1136: '選択した配布書類セットが送信済みのため配布書類を更新できません',
  API_WARN_1137: '配布書類の選択可能な上限数（100個）を超えているため、登録できません。',
  API_WARN_1138: 'タイトルが未入力です。',
  API_WARN_1139: 'サイズ上限(20MB=20,970,496Byte)を超えるファイルが含まれている為、登録できません。',
  API_WARN_1140: '総ファイルサイズの上限(100MB)を超える為、承認依頼できません。',
  API_WARN_1141: 'SSO連携済みのため「ユーザーID」「ユーザー名」「部署名」「メールアドレス」は変更できません。',
  API_WARN_1142: '選択した住宅ローン仮審査（SET）申込物件情報が存在しません。',
  API_WARN_1143: '選択した住宅ローン仮審査申込情報が存在しません。',
  API_WARN_1144: '選択した住宅ローン仮審査（SET）SET物件紐付け情報が存在しません。',
  API_WARN_1145: '選択した住宅ローン仮審査（SET）申込物件情報はすでに紐付けされています。',
  API_WARN_1146: 'サイズ上限(20MB=20,970,496Byte)を超える為、登録できません。',
  API_WARN_1147: 'SET連携していません。',
  API_WARN_1148: '選択した関係者は既に削除されています。',
  API_WARN_1149: '選択した関係者に誤りがあります。再度選択してください。',
  API_WARN_1150: '入力した関係者は既に登録されています。',
  API_WARN_1151: '選択した関係者には関連物件が存在する為、削除できません。',
  API_WARN_1152: '関係者情報に未入力があります。',
  API_WARN_1153: '原契約（関係者情報）に未入力があります。',
  API_WARN_1154: '選択したタグ情報が存在しません。',
  API_WARN_1155: '選択した契約拡張情報が存在しません。',
  API_WARN_1156: '契約拡張情報の表示名が重複しています。',
  API_WARN_1157: '印字タグ情報が存在しません。',
  API_WARN_1158: '入力したWES物件コードは既に登録されています。',
  API_WARN_1159: '同一契約書類セット内でファイル名が重複しています。',
  API_WARN_1160: '契約書類セットのファイル名に「合意締結証明書.pdf」は使用できません。',
  API_WARN_1161: 'WES会社情報が変更された可能性があります。',
  API_WARN_1162: '使用している発行依頼情報が存在する為、更新できません。',
  API_WARN_1163: '文字数を超過しています。',
  API_WARN_1164: '形式が不正です。',
  API_WARN_1165: '入力したWES会社コードは既に登録されています。', // DG管理画面でのみ使用
  API_WARN_1166: '入力した契約者IDが契約グループ内で既に登録されています。',
  API_WARN_1167: '使用している物件情報が存在するため、削除できません。', // DG管理画面でのみ使用
  API_WARN_1168: 'クラウドサインのユーザー定義の項目の番号が重複しています。', // DG管理画面でのみ使用
  API_WARN_1169: '配布書類セット返信ファイルの取得に失敗しました。',
  API_WARN_1170: 'ユーザーID、またはパスワードに誤りがあります。ユーザーIDを忘れてしまった場合は、営業担当者までご連絡をお願いいたします。',
  API_WARN_1171: 'ユーザーID、またはパスワードに誤りがあります。ユーザーIDを忘れてしまった場合は、システム管理者までご連絡をお願いいたします。',
  API_WARN_1172: 'ダウンロードしたファイルと同じ形式のファイルをアップロードしてください。',

  // API（異常系）
  API_ERR_1000: 'ユーザー情報一覧の取得に失敗しました。',
  API_ERR_1001: 'ユーザーの追加に失敗しました。',
  API_ERR_1002: 'ユーザーの更新に失敗しました。',
  API_ERR_1003: 'ユーザーの削除に失敗しました。',
  API_ERR_1004: '契約書テンプレート一覧の取得に失敗しました。',
  API_ERR_1005: '利用物件の更新に失敗しました。',
  API_ERR_1006: '集計結果の取得に失敗しました。',
  API_ERR_1007: 'プリファレンス情報一覧の取得に失敗しました。',
  API_ERR_1008: 'プリファレンスの更新に失敗しました。',
  API_ERR_1009: '宅建士一覧の取得に失敗しました。',
  API_ERR_1010: '宅建士の追加に失敗しました。',
  API_ERR_1011: '宅建士の更新に失敗しました。',
  API_ERR_1012: '宅建士の削除に失敗しました。',
  API_ERR_1013: '物件情報一覧の取得に失敗しました。',
  API_ERR_1014: '物件情報の取得に失敗しました。',
  API_ERR_1015: '物件の追加に失敗しました。',
  API_ERR_1016: '物件の更新に失敗しました。',
  API_ERR_1017: '物件担当者の追加に失敗しました。',
  API_ERR_1018: '物件担当者の削除に失敗しました。',

  API_ERR_1021: '宅建士情報の取得に失敗しました。',
  API_ERR_1022: '物件宅建士の追加に失敗しました。',
  API_ERR_1023: '物件宅建士の削除に失敗しました。',
  API_ERR_1024: '個人設定の更新に失敗しました。',
  API_ERR_1025: '2要素認証設定のリセットに失敗しました。',
  API_ERR_1026: 'ログアウトに失敗しました。',
  API_ERR_1027: '認証処理に失敗しました。',
  API_ERR_1028: '認証情報の登録に失敗しました。',
  API_ERR_1029: 'お客様情報の取得に失敗しました。',
  API_ERR_1030: '認証情報の取得に失敗しました。',
  API_ERR_1031: 'ログイン処理に失敗しました。',
  API_ERR_1032: 'アクセスコードの取得に失敗しました。',
  API_ERR_1033: '契約グループ一覧の取得に失敗しました。',
  API_ERR_1034: '契約グループの追加に失敗しました。',
  API_ERR_1035: '契約者一覧の取得に失敗しました。',
  API_ERR_1036: '契約ステータスの更新に失敗しました。',
  API_ERR_1037: '締結済み書類の取得に失敗しました。',
  API_ERR_1038: '書類セット一覧の取得に失敗しました。',
  API_ERR_1039: '共有者の解約に失敗しました。',
  API_ERR_1040: '個人情報詳細の更新に失敗しました。',
  API_ERR_1041: '共有者の解約の取り消しに失敗しました',
  API_ERR_1042: '認証メールの送信に失敗しました。',
  API_ERR_1043: '代理人設定の更新に失敗しました。',
  API_ERR_1044: '代理人情報の更新に失敗しました。',
  API_ERR_1045: '書類セット情報の取得に失敗しました。',
  API_ERR_1046: '書類セットの削除に失敗しました。',
  API_ERR_1047: '書類セットの送信に失敗しました。',
  API_ERR_1048: '送信取り消しに失敗しました。',
  API_ERR_1049: '承認に失敗しました。',
  API_ERR_1050: '承認の差し戻しに失敗しました。',
  API_ERR_1051: '承認依頼取り消しに失敗しました。',
  API_ERR_1052: '書類セットの一時保存に失敗しました。',
  API_ERR_1053: 'PDFファイルのアップロードに失敗しました。',
  API_ERR_1054: '承認依頼に失敗しました。',
  API_ERR_1055: '書類セットの登録に失敗しました。',
  API_ERR_1056: '署名済書類セットのダウンロードに失敗しました。',
  API_ERR_1057: '契約グループ情報の取得に失敗しました。',
  API_ERR_1058: 'ユーザー情報の取得に失敗しました。',
  API_ERR_1059: '権限がありません。',
  API_ERR_1060: 'PDFファイルの削除に失敗しました。',
  API_ERR_1061: 'CSVファイルの作成に失敗しました。',
  API_ERR_1062: '書類PDFの更新に失敗しました。',
  API_ERR_1063: 'クラウドサインの更新に失敗しました。',
  API_ERR_1064: 'アクセスコード取得メールの送信に失敗しました。 ',
  API_ERR_1065: '認証情報の取得に失敗しました。登録メールアドレスが変更された可能性があります。',
  API_ERR_1066: '承認依頼通知メールの送信に失敗しました。',
  API_ERR_1067: '添付したファイルのデータサイズが制限を超えています。',
  API_ERR_1068: 'パスワード再発行メールの送信に失敗しました。',
  API_ERR_1069: 'パスワードの変更に失敗しました。',
  API_ERR_1070: '許可されていないIPアドレスの為、アクセスできません。',
  API_ERR_1071: '契約書類テンプレートの更新に失敗しました。',
  API_ERR_1072: '契約書類テンプレートの取得に失敗しました。',
  API_ERR_1073: '契約書類テンプレートの複製に失敗しました。',
  API_ERR_1074: 'アカウント情報の取得に失敗しました。',
  API_ERR_1075: '利用規約の取得に失敗しました。',
  API_ERR_1076: '監査ログの取得に失敗しました。',
  API_ERR_1077: '書類セットの署名依頼に失敗しました。',
  API_ERR_1078: '操作ログのダウンロードに失敗しました。',
  API_ERR_1079: '操作ログの登録に失敗しました。',
  API_ERR_1080: '連結ファイル（PDF）の更新に失敗しました。',
  API_ERR_1081: 'zipファイルの作成依頼に失敗しました。',
  API_ERR_1082: '契約書類の取得に失敗しました。',
  API_ERR_1083: 'リマインドの送信に失敗しました。',
  API_ERR_1084: '現在のパスワードに誤りがあります。',
  API_ERR_1085: '試行回数の上限を超えました。1時間以上間隔を空けてから再度お試しください。',
  API_ERR_1086: '手付金等保証証書の取得に失敗しました。',
  API_ERR_1087: '手付金等保証証書発行依頼情報の取得に失敗しました。',
  API_ERR_1088: 'FIPS物件情報の取得に失敗しました。',
  API_ERR_1089: '手付金等保証証書の発行に失敗しました。',
  API_ERR_1090: 'FIPS会社情報の取得に失敗しました。',
  API_ERR_1091: '保証終了報告（引渡／解除／取消）に失敗しました。',
  API_ERR_1092: '手付金保証証書交付メールの送信に失敗しました。',
  API_ERR_1093: '契約書類セット情報の取得に失敗しました。',
  API_ERR_1094: '契約書類セットの署名依頼に失敗しました。',
  API_ERR_1095: '配布書類セット情報の取得に失敗しました。',
  API_ERR_1096: '配布書類セット通知メールの送信に失敗しました。',
  API_ERR_1097: '配布書類の更新に失敗しました。',
  API_ERR_1098: '仮審査申込情報の取得に失敗しました。',
  API_ERR_1099: 'WES物件情報の取得に失敗しました',
  API_ERR_1100: 'WES会社情報の取得に失敗しました。',
  API_ERR_1101: 'テナント情報の取得に失敗しました。',
  API_ERR_1102: 'WES保証証書発行依頼情報の取得に失敗しました。',
  API_ERR_1103: 'WES保証証書の発行依頼に失敗しました。',
  API_ERR_1104: 'WES保証証書情報の取得に失敗しました。',
  API_ERR_1105: '保証終了報告（回収／書損）に失敗しました。',
  API_ERR_1106: 'WES保証証書を発行したことのある契約グループは、一括発行できません。',
  API_ERR_1107: 'WES保証証書が複数枚ある契約グループは、一括終了できません。',
  API_ERR_1108: 'WES保証証書が複数枚ある契約グループは、一括交付できません。',
  API_ERR_1109: 'WES保証証書の交付に失敗しました。',
  API_ERR_1110: 'WES物件情報の登録に失敗しました。',
  API_ERR_1111: 'WES物件情報の削除に失敗しました。',
  API_ERR_1112: '手付金等保証の会社情報が設定されていません。',
  API_ERR_1113: 'クラウドサインユーザー定義書類情報の取得に失敗しました。', // DG管理画面でのみ使用
  API_ERR_1114: '配布書類セット返信ファイルの更新に失敗しました。',
}
