import CONST from '@/data/const'

const defaultPagination = {
  page: 1,
  searchText: '',
  filterStatus: CONST.DISTRIBUTION_DOCUMENT.REPLY_CONFIRM_STATUS.ALL, // すべて
  pageCount: 0,
}

export default {
  state: {
    pagination: defaultPagination,
  },

  getters: {
    getPagination: (state) => state.pagination,
  },

  mutations: {
    savePagination(state, data) {
      state.pagination = data
    },
  },

  actions: {
    setPagination({ commit, state }, payload) {
      commit('savePagination', { ...state.pagination, ...payload })
    },

    resetPagination({ commit }) {
      commit('savePagination', defaultPagination)
    },
  },
}
