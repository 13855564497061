import CONST from '@/data/const'

// ファイルをBLOB形式からダウンロードする
const downloadBlob = (blob, fileName) => {
  const anchor = document.createElement('a')
  anchor.href = URL.createObjectURL(blob)
  anchor.download = fileName
  anchor.click()
  URL.revokeObjectURL(anchor.href)
}

// ファイルをURLからダウンロードする
const downloadUrl = (url, fileName) => {
  const anchor = document.createElement('a')
  anchor.href = url
  // chrome65から別オリジンの場合はdownload属性が効かないので注意すること
  // https://iwb.jp/a-tag-download-attribute-not-use-for-image-links/?utm_source=rss&utm_medium=rss&utm_campaign=a-tag-download-attribute-not-use-for-image-links
  anchor.download = fileName
  anchor.click()
}

// エラーハンドラー
const errorHandler = async (e) => {
  if (e.response?.data instanceof Blob) {
    e.response.data = JSON.parse(await e.response.data.text())
  }
  if (e.response?.data?.error) {
    const error = new Error(CONST.MESSAGE[e.response.data.error.message_id])
    error.response = e.response
    throw error
  }
}

// URLからファイルサイズ(byte)を取得
// ローカルファイルのサイズは取得できないので注意すること
// 取得失敗時は、0を返す
const getFileSizeFromUrl = async (url) => {
  try {
    const res = await fetch(url, {
      mode: 'cors'
    })
    const byte = res.headers.get('Content-Length')
    if (byte) {
      return byte
    }
  } catch (e) {
    // console.log(e)
  }
  return 0
}

/**
 * byteから表示用のファイルサイズを取得
 * KB以上の場合は、小数点第二位で切り捨て
 * @param {*} byte
 * @returns 表示用ファイルサイズ（*KBなど）
 */
const getDisplayFileSizeStr = (byte) => {
  const kb = 1024
  const mb = kb ** 2
  const gb = kb ** 3

  let target = null
  let unit = 'byte'

  if (byte >= gb) {
    target = gb
    unit = 'GB'
  } else if (byte >= mb) {
    target = mb
    unit = 'MB'
  } else if (byte >= kb) {
    target = kb
    unit = 'KB'
  }

  // byteの場合
  if (target === null) {
    return `${byte}${unit}`
  }
  // KB以上の場合
  // 小数点第2位で切り捨て
  const size = Math.floor((byte / target) * 100) / 100
  return `${size}${unit}`
}

/**
 * ファイル名に拡張子を追加する
 * すでに拡張子がついている場合は、何もしない
 * @param {string} fileName
 * @param {string} extention 拡張子（.pdf/.docxなど）
 *
 * @returns 拡張子付きファイル名（*KBなど）
 */
const appendExtention = (fileName, extention) => {
  // extentionがpdfの場合、
  // .pdf（または.PDF）で終わらない場合は、ファイル名に拡張子（.pdf）をつける
  extention = extention.startsWith('.') ? extention : `.${extention}`
  return fileName.endsWith(extention.toLowerCase()) || fileName.endsWith(extention.toLowerCase()) ? fileName : `${fileName}${extention}`
}

/**
 * fileからアップロードに必要な情報を取得する
 * @params {file} ファイルオブジェクト
 *
 * @return {Object}
 */
const getFileTypeInfo = (file) => {
  if (file?.type === CONST.FILE_TYPE.PDF.CONTENT_TYPE || file?.distribution_file_type === CONST.FILE_TYPE.PDF.TYPE) {
    return {
      typeText: CONST.FILE_TYPE.PDF.TYPE,
      contentType: CONST.FILE_TYPE.PDF.CONTENT_TYPE,
      icon: CONST.FILE_TYPE.PDF.ICON,
    }
  } else if (file?.type === CONST.FILE_TYPE.WORD.CONTENT_TYPE || file?.distribution_file_type === CONST.FILE_TYPE.WORD.TYPE) {
    return {
      typeText: CONST.FILE_TYPE.WORD.TYPE,
      contentType: CONST.FILE_TYPE.WORD.CONTENT_TYPE,
      icon: CONST.FILE_TYPE.WORD.ICON,
    }
  } else if (file?.type === CONST.FILE_TYPE.EXCEL.CONTENT_TYPE || file?.distribution_file_type === CONST.FILE_TYPE.EXCEL.TYPE) {
    return {
      typeText: CONST.FILE_TYPE.EXCEL.TYPE,
      contentType: CONST.FILE_TYPE.EXCEL.CONTENT_TYPE,
      icon: CONST.FILE_TYPE.EXCEL.ICON,
    }
  } else {
    return { typeText: '', contentType: '', icon: '' }
  }
}

export default {
  downloadBlob,
  downloadUrl,
  errorHandler,
  getFileSizeFromUrl,
  getDisplayFileSizeStr,
  appendExtention,
  getFileTypeInfo,
}
