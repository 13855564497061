
const CONFIRM_DEFAULT_TITLE = 'ユーザー確認'

export default {
  name: 'ConfirmDialog',

  components: {
    BaseButton: () => import('@/components/atoms/BaseButton'),
    ButtonClose: () => import('@/components/atoms/ButtonClose'),
  },

  props: {},

  data() {
    return {
      dialog: false,
      title: CONFIRM_DEFAULT_TITLE,
      message: null,
      warningAlert: null,
      warningAlertDotList: null,
      resolve: null,
      reject: null,
    }
  },

  computed: {},

  methods: {
    open(params) {
      this.dialog = true

      if (params.close) {
        this.dialog = false
      }

      if (params.title) {
        this.title = params.title
      }

      if (params.warningAlert) {
        this.warningAlert = params.warningAlert
      }

      if (params.warningAlertDotList && Array.isArray(params.warningAlertDotList)) {
        this.warningAlertDotList = params.warningAlertDotList
      }

      this.message = params.message

      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },

    onClickOk() {
      this.reset()
      this.resolve(true)
    },

    onClickCancel() {
      this.reset()
      this.resolve(false)
    },

    reset() {
      this.title = CONFIRM_DEFAULT_TITLE
      this.message = ''
      this.warningAlert = null
      this.warningAlertDotList = null
      this.dialog = false
    },
  },
}
